/*------------------------------------------------------------------------------------*\
    $COLORS FOR ANGULAR 6 FRONTPAGE (TO BE IMPLEMENTED ALL OVER MITPFA IN TIME...)
    Taget fra designsystemet -
    https://projects.invisionapp.com/dsm/pfa-form/pfa-form/nav/foundations/folder/colors/5ebd1ad4b3f7ec53182b8c6e?mode=preview
\*------------------------------------------------------------------------------------*/

$color__white: #ffffff;

/*
 * Primary color, aka PFA red
 *
 */
$color__primary: #990000;
$color__primary--80: #ad3333;
$color__primary--60: #c16666;
$color__primary--40: #d69999;
$color__primary--20: #eacccc;
$color__primary--10: #f5e6e6;
$color__primary--05: #f9f2f2;

$color__primary--hover: #a11414;

/*
 * Secondary color, aka PFA Petroleum
 *
 */
$color__secondary: #006666;
$color__secondary--80: #338484;
$color__secondary--60: #66a3a3;
$color__secondary--40: #99c1c1;
$color__secondary--20: #cce0e0;
$color__secondary--10: #e6f0f0;
$color__secondary--05: #f2f7f7;

/*
 * Blacks, aka PFA neutral
 *
 */
$color__black: #000000;
$color__black--80: #333333;
$color__black--70: #4d4d4d;
$color__black--60: #666666;
$color__black--40: #999999;
$color__black--20: #cccccc;
$color__black--10: #e5e5e5;
$color__black--07: #ededed;
$color__black--05: #f2f2f2;
$color__black--03: #f7f7f7;
$color__black--02: #fafafa;

$color__black--hover: #434343;

/*
  Systemcolor warning
*/
$color__system: #ffaa00;
$color__system--10: #ffeecc;
$color__wireframe: #3090e9;
$color__wireframe--10: #d6e9fb;

/*
  Text colors
*/
$color__primary--text: #1a1a1a;

/*
  Traffic light cause
 */
$color__traffic-light-go--background: #d9e6d9;
$color__traffic-light-go: #418241;
$color__traffic-light-stop: #c51414;
$color__traffic-light-wait: $color__system;
