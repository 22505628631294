.mat-typography {
  mat-form-field,
  mat-radio-group {
    .mat-mdc-form-field-hint,
    .mat-mdc-form-field-error {
      @include text--sticker();
      line-height: $space-lg;
    }

    .mat-mdc-form-field-error {
      color: $color__system;
    }
  }

  mat-form-field {
    display: block;
    margin-bottom: 24px;

    &.form-field-inline {
      display: inline-block;
      margin: 0 5px;
    }

    &.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
      opacity: 0.04 !important;
    }

    &.form-field-light {
      .mat-mdc-text-field-wrapper {
        background: $color__white;
      }

      &.mat-focused .mat-mdc-form-field-focus-overlay {
        background: $color__white;
      }
    }

    .mat-mdc-form-field-bottom-align::before {
      display: none;
    }
  }

  textarea,
  textarea.mat-input-element {
    resize: none;
  }

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
    background: $color__black--05;
    padding-top: 0.62em;
    @include rounded-corners-top(small);
  }

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix {
    padding-bottom: 0.62em;
  }

  mat-calendar-header .mat-calendar-controls {
    button,
    button .mat-button-wrapper {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      color: $color__primary--text !important;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  .mat-date-range-input-mirror {
    letter-spacing: 0.2px;
  }

  .mat-mdc-form-field-label-wrapper {
    box-sizing: content-box;
  }

  .mat-mdc-form-field-subscript-wrapper {
    min-height: $space-xl;
  }

  .mat-mdc-form-field-hint-spacer {
    display: none;
  }

  .mat-form-field--compact {
    margin-bottom: 0;
  }

  .mat-form-field--short {
    width: 130px;
  }
  .aua-form-field {
    margin-bottom: 0;

    .mat-mdc-form-field-flex {
      background: $color__white;
    }

    &.no-label {
      .mat-mdc-form-field-infix {
        border-top: 0;
      }
    }
  }
}
