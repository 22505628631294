.pfa-sheet {
  height: calc(100vh - 110px);
  max-height: none;
  width: 100vw;
  max-width: 100vw;
  padding: 0;
  justify-content: center;
}

.mat-bottom-sheet-container {
  min-width: 1240px !important;
}
