@import 'scss/abstracts/mixins';

// (light)Stying of html from backend
.backend-html {
  h1 {
    font-size: 18px;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: 22px;
    }
    margin-top: 30px;
    margin-bottom: 10px;
    &.text-uppercase {
      font-size: 24px;
      @include breakpoint(find-value('primary', $breakpoints)) {
        font-size: 28px;
      }
      margin-top: 30px;
    }
  }

  table {
    width: 100%;

    td {
      vertical-align: top;
      p {
        margin-bottom: 0.5em;
      }
    }
  }

  a {
    text-decoration: underline;
  }
}
