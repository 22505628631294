@import 'scss/abstracts/colors';

.mat-typography {
  .mdc-linear-progress__bar-inner {
    border-color: $color__secondary;
  }

  .mdc-linear-progress__buffer {
    background-color: $color__secondary--20;
  }
}
