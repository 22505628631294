@import 'scss/abstracts/colors';
@import 'scss/abstracts/mixins';

.mat-typography {
  .mat-mdc-raised-button:not(.btn) {
    font-family: LatoBold, sans-serif;
    background: $color__primary;
    color: $color__white;
    padding: 8px 20px;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0s !important; // to avoid delay when hiding/showing buttons
    @include rounded-corners(button);
    @include shadow();
    text-align: left;
    min-width: 80px;
    min-height: 46px;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      background: $color__primary--hover;
    }
    &:focus {
      border: 2px solid $color__white;
      box-shadow: 0 0 0 2px $color__black;
    }

    &:disabled,
    &.button--disabled {
      background: $color__black--20 !important;
      box-shadow: none !important;
      color: $color__white !important;

      &:hover {
        cursor: default;
        box-shadow: none;
      }
    }

    &.button-secondary {
      background: $color__black--80;
      &:hover {
        cursor: pointer;
        background: $color__black--hover;
      }
    }

    &.button-tertiary {
      border: 2px solid $color__primary;
    }

    &.button-traffic-light-stop--inverted {
      background-color: $color__white;
      color: $color__traffic-light-stop;
      &:hover,
      &:focus {
        cursor: pointer;
        background: $color__primary--10;
      }
    }

    &.button-primary--level2,
    &.button-secondary--level2 {
      background: transparent;
      &:hover,
      &:focus {
        cursor: pointer;
        background: $color__black--05;
      }
    }

    &.button-tertiary,
    &.button-primary--level2,
    &.button-primary--level3 {
      color: $color__primary;

      &:disabled {
        color: $color__black--60 !important;
      }
    }

    &.button-tertiary,
    &.button-primary--level3,
    &.button-secondary--level3 {
      background-color: transparent;
      box-shadow: none;
      &:hover {
        background-color: $color__black--10;
      }
      &:focus {
        box-shadow: 0 0 0 2px $color__black;
      }
      &:disabled,
      &.button--disabled {
        .mat-button-wrapper {
          color: $color__black--20 !important;
        }
        &:hover {
          cursor: default;
          box-shadow: none;
          background-color: transparent;
        }
      }
      &.button-compact {
        padding-left: 0;
        padding-right: 0;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &.button-secondary--level2,
    &.button-secondary--level3 {
      color: $color__black--80;
    }

    .icon {
      margin: -5px 0;

      &:first-child {
        margin-left: -10px;

        &[class*='icon--arrow-'] {
          margin-left: -15px;
        }
      }

      &:last-child {
        margin-right: -10px;

        &[class*='icon--arrow-'] {
          margin-right: -15px;
        }
      }
    }

    .mdc-button__label {
      display: flex;
      align-items: center;
      z-index: initial;
    }

    .underlined {
      text-decoration: underline;
    }
  }

  .button-with-external-link--primary {
    a.link-external {
      text-decoration: none;
    }
  }
}
