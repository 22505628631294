@import 'scss/abstracts/mixins';

/*------------------------------------*\
    $FONT-STACK
\*------------------------------------*/

/**
  * Font face declarations
  */
@font-face {
  font-family: 'Halant';
  src:
    url('/assets/fonts/Halant-Medium.woff') format('woff'),
    url('/assets/fonts/Halant-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  @include font-smoothing(antialiased);
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype');
  src:
    url('/assets/fonts/Lato-Regular.woff') format('woff'),
    url('/assets/fonts/Lato-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  @include font-smoothing(antialiased);
}
@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Italic.ttf') format('truetype');
  src:
    url('/assets/fonts/Lato-Italic.woff') format('woff'),
    url('/assets/fonts/Lato-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  @include font-smoothing(antialiased);
}

@font-face {
  font-family: 'LatoBlack';
  src: url('/assets/fonts/Lato-Black.ttf') format('truetype');
  src:
    url('/assets/fonts/Lato-Black.woff') format('woff'),
    url('/assets/fonts/Lato-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  @include font-smoothing(antialiased);
}
@font-face {
  font-family: 'LatoBlack';
  src: url('/assets/fonts/Lato-BlackItalic.ttf') format('truetype');
  src:
    url('/assets/fonts/Lato-BlackItalic.woff') format('woff'),
    url('/assets/fonts/Lato-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  @include font-smoothing(antialiased);
}

@font-face {
  font-family: 'LatoBold';
  src: url('/assets/fonts/Lato-Bold.ttf') format('truetype');
  src:
    url('/assets/fonts/Lato-Bold.woff') format('woff'),
    url('/assets/fonts/Lato-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  @include font-smoothing(antialiased);
}
@font-face {
  font-family: 'LatoBold';
  src: url('/assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  src:
    url('/assets/fonts/Lato-BoldItalic.woff') format('woff'),
    url('/assets/fonts/Lato-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  @include font-smoothing(antialiased);
}
