@import 'scss/abstracts/colors';

/**
 This is used for 3.party chat
 */
.intelecomchatheader {
  background-color: $color__primary;
}
.intelecomchatheader__settings {
  display: none;
}

.intelecomchat__info {
  height: 40px;
  background-color: $color__black--20;
}

.intelecomdialog {
  background-color: $color__black--40;
  font: inherit;
}

.intelecomismobile {
  width: 300px !important;
  height: 100% !important;
  max-height: 500px;
}

.intelecomdialog__question-text,
.intelecomdialog__question-arrow {
  background-color: white;
}

.intelecomdialog__question-time,
.intelecomdialog__answer-time {
  color: white;
}

.intelecomchat__infostate {
  background: $color__black--80;
}

.intelecomMsgPnl {
  padding-top: 10px;
}

.intelecomfooter {
  background-color: $color__black--20;
}

.intelecomchat__button {
  background: $color__primary;
}

.intelecomchat__button.send_info {
  width: 100%;
  height: 40px;
  border-radius: 15px;
}

.intelecomchat__button.start_chat {
  width: 100%;
  height: 40px;
  border-radius: 15px;
  color: white;
}

.intelecomchatdialog {
  border-radius: 15px;
  bottom: 15px;
  right: 15px;
  top: inherit !important;
  left: inherit !important;
  border: 0;
  background: inherit;
  color: black;
}

.intelecomcaptcha__title {
  font-size: 15px;
  text-align: left;
  margin-bottom: 10px;
}

.intelecomcaptcha__label {
  font-family: inherit;
  font-size: 13px;
  text-align: left;
}
.intelecomchatoverlay__footer {
  display: none;
}

.intelecomchatoverlay {
  overflow-y: auto;
}

.intelecomenterform__form {
  .intelecomchat__input {
    height: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
  }
  label {
    font-size: 15px;
    font-family: 'Lucida Grande', 'Lucida Sans', 'Lucida Sans Unicode',
      'LucidaGrandeFace', sans-serif;
    font-weight: normal;
  }
  label:first-child {
    font-weight: bold;
  }
  label:not(:first-child) {
    margin: 0;
  }
}
