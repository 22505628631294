@import 'scss/abstracts/mixins';
@import 'scss/abstracts/colors';
@import 'scss/abstracts/defaults';

// *** COLUMN ALIGNMENT *********************************************************
// EX: align-right--column-3 will right align all cells in column 3 (NOT 0-based, 1 is the first column)
$cols: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
@each $col in $cols {
  .align-center--column-#{$col} td:nth-child(#{$col}),
  .align-center--column-#{$col} th:nth-child(#{$col}) {
    text-align: center;
  }

  .align-right--column-#{$col} td:nth-child(#{$col}),
  .align-right--column-#{$col} th:nth-child(#{$col}) {
    text-align: right;
  }

  .align-left--column-#{$col} td:nth-child(#{$col}),
  .align-left--column-#{$col} th:nth-child(#{$col}) {
    text-align: left;
  }

  .focus--column-#{$col} td:nth-child(#{$col}) {
    background-color: $color__black--07;
  }
}

.pfa-table {
  width: 100%;
  border-collapse: collapse;

  th,
  tfoot td:first-child {
    font-family: LatoBold, sans-serif;
  }

  th,
  td {
    border-left: 1px solid $color__black--10;

    &:first-child,
    &.no-divider {
      border-left: 0;
    }

    &.cell-number {
      text-align: right;
    }

    &.cell-offset {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }

    co-icon-info {
      margin-top: -2px;
    }
  }

  th {
    text-align: center;
    vertical-align: top;
    padding: $space-xl $space-xxl;

    @include breakpoint(find-value('tablet', $breakpoints)) {
      padding: $space-xl;
    }
  }

  thead tr:last-child th {
    border-bottom: 5px solid $color__black--10;
  }

  td {
    padding: 40px $space-xxl;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: $color__black--10;

    @include breakpoint(find-value('tablet', $breakpoints)) {
      padding: $space-xxl $space-xl;
    }
  }

  tr.border-top--thick td {
    border-top-width: 5px;
  }

  tbody tr:first-child td {
    border-top-width: 0;
  }

  tfoot tr:first-child td {
    border-top-width: 10px;
  }

  &.thead-align--left th {
    text-align: left;
  }

  &.thead-align--right th {
    text-align: right;
  }

  &.thead-align--center th {
    text-align: center;
  }

  &.pfa-table--compact {
    th,
    td {
      padding: $space-xl;
    }
  }

  &.pfa-table--super-compact {
    th,
    td {
      padding: $space-md;
    }
  }

  &.table-no-border {
    th,
    td {
      border-left: 0;
    }

    tbody td,
    tfoot tr:not(:first-child) td {
      border-top: 0;
    }
  }

  &.pfa-table--list {
    thead tr th {
      border-bottom: solid 2px $color__black--40;
      background-color: $color__secondary--10;
    }

    tbody tr td {
      border-top: solid 1px $color__black--40;
    }
  }
}

// *** RESPONSIVE STUFF *********************************************************

@mixin table-responsive($breakpoint: 799px) {
  @media (max-width: $breakpoint) {
    &.table,
    tbody,
    tfoot,
    tr,
    th,
    td {
      display: block;
    }

    thead {
      display: none;
    }

    td {
      position: relative;
      text-align: left !important;
      border-left: 0;

      &:before {
        content: attr(data-label);
        color: $color__black--80;
        font-family: LatoBold, sans-serif;
        float: left;
        margin-right: $space-sm;
      }

      &.table-mobile-header {
        text-align: center;
      }

      &.cell-hide {
        display: none;
      }
    }

    tbody tr:first-child td {
      border-top-width: 1px;

      &:first-child {
        border-top-width: 0;
      }
    }

    tfoot tr:first-child td:not(:first-child) {
      border-top-width: 1px;
    }

    &.pfa-table--compact {
      th,
      td {
        padding: $space-sm;
      }
    }
  }

  @media (max-width: 480px) {
    td:before {
      float: none;
      display: block;
      margin-right: 0;
    }
  }

  .spacer-row {
    display: none;
  }

  @media (max-width: $breakpoint) {
    tr.uneven-row {
      background-color: $color__black--03;
    }
    tr td {
      padding: $space-lg $space-md;
    }
    .spacer-row {
      display: inherit;
      padding: $space-md;
    }
  }
}

.table-responsive {
  @include table-responsive();
}

.table--wide {
  table {
    width: 100%;
  }
}

td.table-emphasized,
tr.table-emphasized td {
  background-color: $color__black--05;
}

tr.forced-thin-border td {
  border-top-width: 1px !important;
}
tr.forced-thick-border td {
  border-top-width: 5px !important;
  @include breakpoint(find-value('primary--min', $breakpoints)) {
    &:not(:first-of-type) {
      border-top-width: 1px !important;
    }
  }
}

td.responsiv-top-padding {
  @include breakpoint(find-value('primary--min', $breakpoints)) {
    padding-top: 60px !important;
  }
}

.mobile-only--table {
  display: none;
  @include breakpoint(find-value('primary--min', $breakpoints)) {
    display: table;
  }
}

.desktop-only--table {
  display: none;
  @include breakpoint(find-value('primary', $breakpoints)) {
    display: table;
  }
}
