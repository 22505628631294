.mat-select-value {
  color: $color__black--60 !important;
}

.mat-mdc-form-field-disabled .mat-select-value {
  color: rgba($color__black, 0.38) !important;
}

.mat-mdc-select-panel .mat-mdc-option {
  line-height: 1.3em;
  height: auto;
  min-height: 48px;
  white-space: normal;
  padding: 10px;
  color: $color__black--60;
  border-bottom: 1px solid $color__black--05;
}

.cdk-overlay-pane:has(> .mat-mdc-select-panel) {
  // MHC: :has selector does not work for firefox - but everyone else gets a better design
  width: auto !important;
}
