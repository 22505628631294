@import 'scss/abstracts/colors';
@import 'scss/abstracts/mixins';

.cdk-overlay-pane {
  @media (max-width: 800px) {
    max-width: 90vw !important;
  }
  @media (max-width: 500px) {
    max-width: 95vw !important;
  }
}

.cdk-overlay-container {
  .popup--small {
    width: 500px;
  }
  .popup--medium {
    width: 800px;
  }
  .popup--large {
    width: 1000px;
  }
  .popup--full-content {
    width: 1240px;
  }
  .popup--extra-large {
    max-width: none !important;
    max-height: none !important;

    width: calc(100% - 1em) !important;
    height: calc(100% - 1em) !important;
    .mat-mdc-dialog-content {
      max-height: calc(100vh - 120px);
      &.pdf--dialog {
        max-height: calc(100vh - 70px);
        height: 100%;
        overflow-y: hidden;
      }
    }
  }
  .popup--extra-large-max {
    max-width: 1000px !important;
    max-height: 1000px !important;
    width: calc(100% - 1em) !important;
    height: calc(100% - 1em) !important;
    .mat-mdc-dialog-content {
      max-height: calc(100vh - 80px);
    }
  }
  .popup--sidebar {
    height: 100%;
    transform: translateX(100%);
    animation: transform 0.5s ease forwards;

    @keyframes transform {
      100% {
        transform: translateX(0);
      }
    }

    .mat-mdc-dialog-surface > * {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .mat-mdc-dialog-title {
      padding: $space-xxl 40px;
    }

    co-icon-times,
    .popup-close-button {
      top: 20px;
      right: 40px;
    }

    .mat-mdc-dialog-content {
      padding: 0 40px 40px;
      max-height: initial;
      max-width: 900px;
      flex: 1 auto;
    }

    .mat-mdc-dialog-container .mdc-dialog__surface {
      background-color: $color__black--05;
      border-radius: 0;
    }

    @media (max-width: 800px) {
      max-width: 100% !important;
    }

    @media (max-width: 575px) {
      width: 100%;

      .mat-mdc-dialog-title {
        padding: $space-xl $space-md;
      }

      co-icon-times,
      .popup-close-button {
        top: $space-md;
        right: $space-md;
      }

      .mat-mdc-dialog-content {
        padding: 0 $space-md $space-md;
      }
    }
  }

  .mat-mdc-dialog-container {
    padding: 0;
  }

  .mat-mdc-dialog-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    padding: $space-sm 20px;
    position: relative;
    min-height: 56px;

    > *:not(co-icon-times, .popup-close-button) {
      margin: 0;
      line-height: 100%;
      padding-right: 40px;
    }
  }

  mat-dialog-content.mat-mdc-dialog-content {
    padding-top: 20px;
    margin: 0;
    @include text--body();
  }

  .mat-mdc-dialog-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 20px;
    padding-right: 20px;
  }

  co-icon-times,
  .popup-close-button {
    cursor: pointer;
    position: absolute;
    top: $space-sm;
    right: 20px;
  }

  .popup-close-button {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background: url('../../images/icon_times.svg') no-repeat 0 0;
  }
}

.mat-mdc-dialog-title.mdc-dialog__title::before {
  display: none;
}
