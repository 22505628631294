@import 'scss/abstracts/defaults';
@import 'scss/abstracts/colors';
@import 'scss/abstracts/mixins';

.mat-typography {
  .mat-mdc-tab-list {
    border-bottom: 1px solid $color__black--20;
  }

  .mdc-tab__text-label {
    font-family: LatoBold, sans-serif;
    flex-basis: auto !important;
  }

  mat-tab-group.evenly-spaced .mat-tab-label {
    @media (min-width: 550px) {
      flex-basis: 0 !important;
    }
  }

  .mat-mdc-tab-body-content {
    padding-top: $space-xl;
    overflow: hidden;
  }

  .hide-nav {
    .mat-tab-header {
      display: none;
    }

    .mat-tab-body-content {
      padding-top: 0;
    }
  }
}
