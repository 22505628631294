mat-tooltip-component .mat-mdc-tooltip {
  font-size: $font-size--note;
  background: $color__black;
  border-radius: 8px;
  padding: 10px;
  .mdc-tooltip__surface {
    background: transparent;
  }
}

mat-tooltip-component .tooltip-white {
  background: $color__white;
  color: $color__black--80;
  padding: 15px;
  font-weight: bold;
  overflow: inherit;
  @include shadow();
  .mdc-tooltip__surface {
    background: transparent;
    color: $color__black--80;
  }
}

mat-tooltip-component .triangle-top {
  position: relative;
  margin: 1.5em 2em 2em 1.6em;
  padding: 15px;
  box-sizing: border-box;
  background: $color__white;
  @media screen and (max-width: 800px) {
    margin: 2.5em 2em 2em 1.6em;
  }
}
mat-tooltip-component .triangle-top::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 20px;
  top: 4px;
  left: 50%;
  box-sizing: border-box;

  border: 1em solid $color__white;
  transform-origin: 0 0;
  transform: rotate(135deg);
}
