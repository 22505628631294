@import 'scss/abstracts/defaults';

.spacing-section--over {
  margin-top: 2 * $space-xxl;
}
.spacing-unit--under {
  margin-bottom: $space-xl;
}
.spacing-unit--half-under {
  margin-bottom: $space-xl * 0.5;
}
.spacing-unit--one--half-under {
  margin-bottom: $space-xxl;
}
.spacing-unit--one--half-over {
  margin-top: $space-xxl;
}
.spacing-unit--double-under {
  margin-bottom: $space-xl * 2;
}
.spacing-unit--quarter-under {
  margin-bottom: $space-xl * 0.25;
}
.spacing-unit--over {
  margin-top: $space-xl;
}
.spacing-unit--half-over {
  margin-top: $space-xl * 0.5;
}
.spacing-unit--double-over {
  margin-top: $space-xl * 2;
}
.spacing-unit--quarter-over {
  margin-top: $space-xl * 0.25;
}
.spacing-unit--quarter-left {
  margin-left: $space-xl * 0.25;
}
.spacing-unit--right {
  margin-right: $space-xl;
}
.spacing-unit--quarter-right {
  margin-right: $space-xl * 0.25;
}
