.mat-typography {
  mat-checkbox .mdc-form-field {
    align-items: flex-start;

    .mdc-label {
      margin-top: $space-sm;
    }
  }

  mat-checkbox + .mat-mdc-form-field-error {
    @include text--sticker();
    line-height: $space-lg;
    color: $color__system;
  }
}
