@import 'scss/abstracts/colors';
@import 'scss/abstracts/defaults';

.tag {
  display: inline-block;
  background-color: $color__primary--20;
  border-radius: 7px;
  padding: 1px 7px 0 7px;
  font-size: $font-size--note;
  margin: 2px 4px 2px 0;
  &.small {
    font-size: $font-size--sticker;
    line-height: 15px;
    padding: 2px 6px 1px 6px;
  }
  &.high-contrast {
    color: $color__black--60;
  }
}

.tag-primary {
  background-color: $color__primary;
  color: $color__white;
}

.tag-neutral {
  background-color: $color__black--40;
  color: $color__white;
}

.tag-secondary {
  background-color: $color__secondary--60;
  &,
  strong {
    color: $color__white;
  }
}

.tag.recommendation-tag {
  padding: 10px 15px;
  border-radius: 23px;
  line-height: 17px;
  max-width: 210px;
  strong {
    color: $color__white;
  }
}

.tag-tooltip {
  cursor: inherit;
  padding: 2px 4px 0 7px;
  &.tag-tooltip--tight {
    padding: 0;
  }
  co-icon-info {
    cursor: pointer;
    margin-top: -10px;
    margin-bottom: -7px;
    margin-right: -5px;
    font-size: 31px;
  }
  &.small co-icon-info {
    margin-top: -7px;
    margin-bottom: -5px;
    margin-right: -5px;
    font-size: 27px;
  }
}
