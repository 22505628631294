@import 'scss/abstracts/colors';
@import 'scss/abstracts/defaults';

.mat-mdc-snack-bar-container {
  margin-bottom: 48px !important;
  background-color: transparent !important;
  position: relative;
  .mdc-snackbar__surface {
    min-height: 40px !important;
    padding: 10px 40px 10px 16px !important;
    background: $color__white !important;
    color: $color__black !important;
    border-radius: $border-radius--small !important;
  }

  &.snack-bar-info .mdc-snackbar__surface {
    background: $color__black--80 !important;
  }

  &.snack-bar-error .mdc-snackbar__surface {
    background: $color__system !important;
  }

  &.snack-bar-success .mdc-snackbar__surface {
    background: $color__traffic-light-go !important;
  }

  &.snack-bar-info .mdc-snackbar__surface,
  &.snack-bar-error .mdc-snackbar__surface,
  &.snack-bar-success .mdc-snackbar__surface {
    color: $color__white !important;

    .bar-content {
      justify-content: center;
    }
  }
}

.bar-content {
  display: flex;
  align-items: flex-start;
}
