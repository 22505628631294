// override for material select so it does not clip flag outlines
pfa-country-code-select {
  .mat-select-value {
    overflow: visible !important;
  }
}

.pfa-country-code-select-dropdown {
  .mat-option-text {
    overflow: visible !important;
  }
}
