@import 'scss/abstracts/colors';

$breakpoint-palm--small: 480px;
$breakpoint-palm--smaller: 360px;
$font-size--standard: 13px;
$font-size--tiny: 11px;
.d3-tip {
  z-index: 500;
  line-height: 1;
  padding: 2px;
  font-weight: bold;
  background: #cecece;
  border-radius: 2px;
  pointer-events: none;
  .plan-tooltip-name {
    font-size: $font-size--standard;
    padding-bottom: 0.4em;
    font-weight: normal;
    &.byline {
      font-weight: normal;
      fill: $color__primary;
      color: $color__primary;
      font-size: $font-size--tiny;
    }
  }
  .plan-tooltip-amount {
    font-size: 24px;
  }
}

.d3-tip > div {
  background: $color__white;
  padding: 5px 10px;
  color: #262626;
  font-size: $font-size--tiny;
}

/* Creates a small triangle extender for the tooltip */

.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: #262626;
  position: absolute;
  pointer-events: none;
}

/* Northward tooltips */

.d3-tip.n:after {
  content: '\25BC';
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */

.d3-tip.e:after {
  content: '\25C0';
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */

.d3-tip.s:after {
  content: '\25B2';
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */

.d3-tip.w:after {
  content: '\25B6';
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.bars {
  cursor: pointer;
}
