@import 'scss/abstracts/defaults';
@import 'scss/abstracts/mixins';
@import 'scss/abstracts/colors';

.mat-typography {
  h1,
  .h1 {
    font-family: $font-family--header;
    font-weight: 500;
    font-size: $font-size--biggest;
    letter-spacing: 0;
    line-height: 120%;
    color: $color__primary--text;
    margin-top: 0;
    margin-bottom: 0.5em;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--biggest__desktop;
    }
  }

  h2,
  .h2 {
    font-family: $font-family--header;
    font-weight: 500;
    font-size: $font-size--bigger;
    letter-spacing: 0;
    line-height: 110%;
    color: $color__primary--text;
    margin-top: 0;
    margin-bottom: 0.5em;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--bigger__desktop;
    }
  }

  h3,
  .h3 {
    font-family: LatoBold, sans-serif;
    font-size: $font-size--big;
    letter-spacing: 0.65px;
    line-height: 135%;
    color: $color__primary--text;
    margin-top: 0;
    margin-bottom: 0.5em;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--big__desktop;
      letter-spacing: 0.8px;
    }
  }

  h4,
  .h4 {
    font-family: LatoBold, sans-serif;
    font-size: $font-size--manchet;
    letter-spacing: 0.6px;
    line-height: 120%;
    color: $color__primary--text;
    margin-top: 0;
    margin-bottom: 0.5em;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--manchet__desktop;
    }
  }

  h5,
  .h5 {
    font-family: LatoBold, sans-serif;
    font-size: $font-size--normal;
    letter-spacing: 0.55px;
    line-height: 130%;
    color: $color__primary--text;
    margin-top: 0;
    margin-bottom: 0.5em;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--manchet;
    }
  }
}

.text--manchet {
  font-family: $font-family--base;
  font-size: $font-size--manchet;
  line-height: 135%;
  letter-spacing: 0.525px;
  @include breakpoint(find-value('primary', $breakpoints)) {
    font-size: $font-size--manchet__desktop;
    letter-spacing: 0.6px;
  }
}
.text--manchet-light {
  @extend .text--manchet;
  color: $color__black--60;
  strong {
    color: $color__black--80;
  }
}

.disabled strong {
  color: $color__black--60;
}

/*------------------------------------*\
    Defaults paragraphs
\*------------------------------------*/

body,
button,
textarea,
input,
select {
  @include text--body();
}

p {
  margin-top: 0;
  margin-bottom: 0.8em;
}

.text--note {
  @include text--note();
  color: $color__black--40;

  .link-external:after,
  .link-telephone:before,
  .link-email:before {
    font-size: $font-size--manchet__desktop;
    margin: -8px 0;
  }
  &.small {
    font-size: $font-size--sticker;
  }
}

.text--mark {
  @include text--sticker();
}

b,
.text--bold {
  font-family: LatoBold, sans-serif;
  font-weight: bold; // for use with fallback font, does not affect LatoBold
}

.underlined {
  text-decoration: underline;
}

strong,
.text--emphasized {
  font-family: LatoBlack, sans-serif;
  color: $color__primary--text;
}

em {
  color: $color__primary;
  font-style: normal;
}

.messaging-thread .from-customer strong,
.white-text strong {
  color: $color__white;
}

.text--grey {
  color: $color__black--40;
}

.text--error,
.text--error a {
  color: $color__system;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a {
  color: $color__black--80;
  text-decoration: none;

  &.link {
    text-decoration: underline;
    cursor: pointer;
    transition-duration: 0s;
    color: $color__primary;
  }

  &:hover,
  &:active {
    color: $color__primary--hover;
  }

  &.no-hover-effect:hover {
    color: $color__black--80;
  }
}

.text--color-primary {
  color: $color__primary !important;
}

.link-external,
.link-telephone,
.link-email {
  text-decoration: underline;

  &:before,
  &:after {
    content: '';
    width: 1em;
    height: 1em;
    font-size: 28px;
    margin: -9px 0;
  }
}

.link-external:after,
.link-telephone:before,
.link-email:before {
  display: inline-block;
}

.link-external:after {
  background: no-repeat url('/assets/images/external-link.svg');
}
.button-with-external-link--primary {
  .link-external:after {
    background: no-repeat url('/assets/images/external-link-primary.svg');
  }
}

.link-telephone:before {
  background: no-repeat url('/assets/images/phone.svg');
}

.link-email:before {
  background: no-repeat url('/assets/images/email.svg');
}

.link-external__no-icon:after {
  display: none;
}

.footer--wrapper ul li .link {
  color: $color__black--60;
}

.card-title {
  font-size: 20px;
}

.message-body {
  a {
    @extend .link;
    @extend .link-external;
  }
  em {
    font-style: italic;
  }
}

hr {
  width: 100%;
  height: 1px;
  outline: none;
  border: none;
  background: $color__black--80;
  @include breakpoint('print') {
    background: none;
    border-bottom: 2px solid $color__black--80;
  }
}

.hr--light {
  background-color: $color__black--20;
}
.hr--light-40 {
  background-color: $color__black--40;
}
.capitalize {
  text-transform: capitalize;
}

.raadgiver-info {
  margin: 0 30px 30px 30px;
  background-color: $color__black--10;
  border: 3px ridge $color__black--20;
  padding: 5px;
  legend {
    padding: 0 3px;
    text-transform: uppercase;
    color: $color__black--60;
    font-weight: bold;
  }
}

.raadgiver-info--tight {
  @extend .raadgiver-info;
  margin-right: 0;
  margin-left: 0;
}

sub {
  display: inline-block;
  margin-bottom: -8px;
}

/*------------------------------------*\
    Colors
\*------------------------------------*/
.color--black {
  color: $color__primary--text;
}

.color--primary {
  color: $color__primary;
}

.color--secondary {
  color: $color--secondary;
}
