@import 'breakpoint-sass/stylesheets/breakpoint';
/**
 * Final list of global breakpoints.
 *
 * Any new breakpoints should be added at component scope.
 */
$breakpoints: (
  'primary' (min-width 800px),
  'primary--min' (max-width 799px),
  'tablet' (max-width 1024px)
);
