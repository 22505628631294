@import 'scss/layout/pfa_bootstrap-grid';
@import 'scss/base/font-stack';
@import 'scss/abstracts/space';
@import 'scss/material/init';

/*------------------------------------*\
    Styling for new Angular 6 pages
\*------------------------------------*/

html body {
  height: 100%;
  margin: 0;
}

html {
  * {
    box-sizing: border-box;
  }

  @import 'scss/base/text';
  @import 'scss/base/lists';
  @import 'scss/base/inputs';
  @import 'scss/abstracts/defaults';
  @import 'scss/abstracts/colors';
  @import 'scss/abstracts/mixins';
  @import 'scss/layout/utility';
  @import 'scss/components/button';
  @import 'scss/components/icons';
  @import 'scss/components/table';
  @import 'scss/components/signing';
  @import 'scss/components/slider';
  @import 'scss/components/slider-form';
  @import 'scss/components/backend-html';
  @import 'scss/components/fusioncharts';
  @import 'scss/components/chat';
  @import 'scss/components/tag';
  @import 'scss/components/country-code-select';
  @import 'scss/components/quick-action-bar';

  @import './globals/mitpfa/pension-payout-plan';

  &.cdk-global-scrollblock {
    overflow-y: auto !important;
  }

  background: $color__black--05;

  .content {
    flex: 1 1 auto;
    min-height: 200px;
    position: relative;

    > router-outlet + * {
      display: block;
      @include limit-screen-width();
    }
  }

  .limit-screen-width {
    @include limit-screen-width();
  }
}

.payout-chart {
  position: relative;
  display: flex;
  justify-content: center;
  .bar {
    cursor: pointer;
  }
  .x {
    &.axis {
      .domain {
        display: none;
      }
    }
  }
  .y {
    &.axis {
      .domain {
        display: none;
      }
    }
  }
  .payout-chart-tooltip {
    position: absolute;
    top: 100px;
    justify-self: center;
    border-radius: 10px;
    background-color: $color__white;
    opacity: 0;
    z-index: 9;

    @include breakpoint(find-value('primary--min', $breakpoints)) {
      left: 0;
      font-size: 0.9em;
      top: 80px;
    }

    @media (max-width: 500px) {
      max-width: 300px;
    }

    .close-payout-plan-tooltip {
      height: 30px;
      width: 30px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    .pfa-table {
      border-collapse: separate;

      th {
        padding: 18px 20px 12px;
      }

      td {
        padding: 12px 20px;
      }

      thead tr:last-child th {
        border-bottom-width: 1px;
      }

      td {
        color: $color__black;
      }

      .dot {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: $color__black--20;
        display: inline-block;
        margin-right: 10px;
      }

      .category-title {
        background-color: $color__white;
        box-shadow: 8px 0px 10px 0px rgb(0 0 0 / 5%);
      }

      .year-title {
        font-weight: 200;
        font-family: Lato;
        color: $color__black--40;
      }

      td.label-cell {
        background-color: $color__white;
        box-shadow: 8px 0px 10px 0px rgb(0 0 0 / 5%);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }

  .g-needs-line {
    text {
      color: $color__primary--80;
      fill: $color__primary--80;
      text-anchor: end;
      font-weight: 600;
    }
    .needs-line {
      stroke: $color__primary--80;
      stroke-width: 2px;
      stroke-dasharray: 1, 2;
    }
  }
}

.mat-typography p {
  margin: 0 0 12px;
}
