@import 'scss/abstracts/colors';

.icon__on-primary--stroke {
  .icon-outline {
    stroke: $color__white;
  }
  &:hover .icon-outline,
  &:focus .icon-outline {
    stroke: $color__primary;
  }
  &:disabled {
    .icon-outline {
      stroke: $color__black--60;
    }
    &:hover .icon-outline,
    &:focus .icon-outline {
      stroke: $color__black--60;
    }
  }
}

.icon-right-aligned {
  margin-right: -10px;
}
.icon-close-to-left {
  margin-left: -5px;
}

.dummy-icon {
  height: 40px;
  width: 40px;
  display: inline-flex;
  vertical-align: middle;
}

.dummy-icon--vertical-only {
  @extend .dummy-icon;
  width: 1px;
}
