@import 'scss/abstracts/defaults';
@import 'scss/abstracts/mixins';
@import 'scss/abstracts/colors';

.nowrap {
  white-space: nowrap;
}

.float--left {
  float: left;
}
.float--right {
  float: right;
}

.align--left {
  text-align: left;
}
.align--center {
  text-align: center;
}
.align--right {
  text-align: right;
}
.align--justify {
  text-align: justify;
}

.cf:after {
  content: '';
  display: table;
  clear: both;
}

.display-block {
  display: block;
}
.display-inline-block,
.inline-block {
  display: inline-block;
}
.display-flex {
  display: flex;
}

.align-center-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.justify-center-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.align-items-start {
  align-items: flex-start;
}

.vertical-split {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cf {
  clear: both;
}

.spacing-over {
  margin-top: $space-md;
}
.spacing-under {
  margin-bottom: $space-md;
}

.mobile-only {
  display: none;

  @include breakpoint(find-value('primary--min', $breakpoints)) {
    display: inherit;
  }
}

.hidden-mobile {
  @include breakpoint(find-value('primary--min', $breakpoints)) {
    display: none !important;
  }
}

.desktop-only {
  display: none;

  @include breakpoint(find-value('primary', $breakpoints)) {
    display: inherit;
  }
}

.hidden-desktop {
  @include breakpoint(find-value('primary', $breakpoints)) {
    display: none;
  }
}

.profile-choice-container {
  svg {
    cursor: pointer !important;
    left: 0 !important;
    background: transparent !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.sort-arrow {
  cursor: pointer;
  display: inline-block;
  height: 0;
  width: 0;
  border-style: solid;
  border-color: transparent;
}
.sort-arrow--asc {
  margin-left: 10px;
  border-width: 0 5px 7px 5px;
  border-color: transparent transparent #000000 transparent;
}
.sort-arrow--desc {
  margin-left: 10px;
  border-width: 7px 5px 0 5px;
  border-color: #000000 transparent transparent transparent;
}

.footnote {
  @include text--note();
  margin-top: 15px;
  font-style: italic;
  hr {
    background-color: $color__black--40;
    margin: 0;
    height: 1px;
    width: 50px;
  }
}

.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.width-100 {
  width: 100%;
}

[hidden] {
  display: none !important;
}
