$font-family--base: Lato, Arial, Verdana !default;
$font-family--header: Halant, 'Book Antiqua', 'Times New Roman' !default;

$font-size--biggest: 38px;
$font-size--biggest__desktop: 48px;

$font-size--bigger: 34px;
$font-size--bigger__desktop: 40px;

$font-size--big: 26px;
$font-size--big__desktop: 32px;

$font-size--manchet: 20px;
$font-size--manchet__desktop: 24px;

$font-size--normal: 16px;
$font-size--note: 14px;
$font-size--sticker: 12px;

// *** SPACING BLOCKS *******************
$space-xs: 4px;
$space-sm: 8px;
$space-md: 12px;
$space-lg: 16px;
$space-xl: 24px;
$space-xxl: 32px;
$space-section: 64px;

$border-radius--normal: 16px;
$border-radius--md: 12px;
$border-radius--small: 8px;
$border-radius--button: 8px;
$border-radius--xs: 4px;

$width--wide: 1340px;
