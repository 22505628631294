@import 'scss/abstracts/defaults';
@import 'scss/abstracts/colors';

.ngx-slider {
  .ngx-slider-bar {
    height: 10px;
    background: $color__secondary--80;
    border-radius: 10px;
  }
  .ngx-slider-selection-bar .ngx-slider-bar {
    background: $color__primary;
  }

  &.green-color {
    .ngx-slider-bar {
      background: $color__black;
    }
    .ngx-slider-selection-bar .ngx-slider-bar {
      background: $color__secondary--80;
    }
  }

  .ngx-slider-pointer {
    background: $color__white;
    width: 40px;
    height: 20px;
    top: -6px;
    box-shadow:
      0 2px 0 0 #fff inset,
      0 2.5px 4px 0 rgba($color__black, 0.5);
    outline: none;
    &::before {
      content: '';
      width: 1px;
      height: 8px;
      position: absolute;
      top: 7px;
      left: 17px;
      background: $color__black--40;
    }
    &::after {
      content: '';
      width: 1px;
      height: 8px;
      position: absolute;
      top: 7px;
      left: 19px;
      background: $color__black--40;
    }
  }

  &[disabled] {
    .ngx-slider-bar {
      background: $color__black--20;
      opacity: 0.4;
    }
    .ngx-slider-selection-bar .ngx-slider-bar {
      background: $color__black--40;
    }
    .ngx-slider-pointer {
      background: $color__black--20;
      box-shadow: none;
      border: 1px solid $color__black--40;
      &::before,
      &::after {
        top: 6px;
      }
    }
  }
}
