@use 'sass:math';

@import 'scss/abstracts/defaults';
@import 'scss/abstracts/breakpoints';

/*------------------------------------*\
    $MIXINS
\*------------------------------------*/

@function find-value($key, $list) {
  // sassfix: use map/getmap
  @each $item in $list {
    @if ($key == nth($item, 1)) {
      @return nth($item, 2);
    }
  }
  @return false;
}

/*Breakpoint property array*/
@mixin breakpoint-property-array($list, $property, $important: false) {
  @each $item in $list {
    $key: nth($item, 1);
    $declaration: nth($item, 2);

    @include breakpoint(find-value($key, $breakpoints)) {
      @if $important == true {
        #{$property}: $declaration !important;
      } @else {
        #{$property}: $declaration;
      }
    }

    /*@debug 'name (breakpoint): ' + $key + ' declaration: ' + $declaration;*/
  }
}

/**
 * Font smoothing for chrome MAC
 */

@mixin font-smoothing($val: antialiased) {
  -webkit-font-smoothing: $val;
  -moz-font-smoothing: $val;
  -ms-font-smoothing: $val;
  -o-font-smoothing: $val;
  text-rendering: optimizeLegibility;
}

@mixin text--body($responsive: true) {
  font-family: $font-family--base;
  font-weight: normal;
  font-size: $font-size--normal;
  line-height: 150%;
  letter-spacing: 0.2px;
  color: $color__black--60;
}

@mixin text--note() {
  font-family: $font-family--base;
  font-weight: normal;
  font-size: $font-size--note;
  letter-spacing: 0.35px;
}

@mixin text--sticker() {
  font-family: $font-family--base;
  font-weight: normal;
  font-size: $font-size--sticker;
  letter-spacing: 0.3px;
  color: $color__primary--text;
  line-height: 145%;
}

@mixin shadow-definition() {
  box-shadow:
    0 8px 24px rgba($color__black, 0.05),
    0 16px 64px rgba($color__black, 0.05);
}
@mixin shadow() {
  transition: box-shadow 220ms ease-in;
  @include shadow-definition();
  &:hover {
    transition: box-shadow 150ms ease-in;
    box-shadow:
      0 40px 48px rgba($color__black, 0.05),
      0 32px 80px rgba($color__black, 0.05);
  }
}
@mixin shadow-not-interactive() {
  transition: box-shadow 220ms ease-in;
  @include shadow-definition();
  &:hover {
    @include shadow-definition();
  }
}

@function rounded-corners-size($size-input: normal) {
  $size: $border-radius--normal;
  @if $size-input == none {
    $size: 0;
  }
  @if $size-input == small {
    $size: $border-radius--small;
  }
  @if $size-input == button {
    $size: $border-radius--button;
  }
  @return $size;
}
@mixin rounded-corners($size-input: normal) {
  border-radius: rounded-corners-size($size-input);
}
@mixin rounded-corners-top($size-input: normal) {
  border-top-left-radius: rounded-corners-size($size-input);
  border-top-right-radius: rounded-corners-size($size-input);
}
@mixin rounded-corners-bottom($size-input: normal) {
  border-bottom-left-radius: rounded-corners-size($size-input);
  border-bottom-right-radius: rounded-corners-size($size-input);
}
@mixin rounded-corners-left($size-input: normal) {
  border-top-left-radius: rounded-corners-size($size-input);
  border-bottom-left-radius: rounded-corners-size($size-input);
}
@mixin rounded-corners-right($size-input: normal) {
  border-top-right-radius: rounded-corners-size($size-input);
  border-bottom-right-radius: rounded-corners-size($size-input);
}
@mixin card-image {
  :host {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;

    @include breakpoint(find-value('primary', $breakpoints)) {
      top: 20px;
    }
  }

  svg {
    max-height: 220px;
    @include breakpoint(find-value('primary', $breakpoints)) {
      max-height: 250px;
    }
  }
}
@function skeleton-bar($height) {
  @return linear-gradient($color__black--05 #{$height}, transparent 0);
}
@function skeleton-highlight($color: $color__white) {
  @return linear-gradient(
    90deg,
    rgba($color, 0) 0,
    rgba($color, 0.8) 50%,
    rgba($color, 0) 100%
  );
}
@mixin default-transition(
  $properties: all,
  $speed: 300ms,
  $ease: cubic-bezier(0.455, 0.03, 0.515, 0.955),
  $delay: 0s
) {
  -webkit-transition-property: $properties;
  -moz-transition-property: $properties;
  -o-transition-property: $properties;
  -ms-transition-property: $properties;
  transition-property: $properties;
  -webkit-transition-duration: $speed;
  -moz-transition-duration: $speed;
  -o-transition-duration: $speed;
  -ms-transition-duration: $speed;
  transition-duration: $speed;
  -webkit-transition-timing-function: $ease;
  -moz-transition-timing-function: $ease;
  -o-transition-timing-function: $ease;
  -ms-transition-timing-function: $ease;
  transition-timing-function: $ease;
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -ms-transition-delay: $delay;
  transition-delay: $delay;
}
@mixin positioner(
  $top: 0,
  $left: 0,
  $bottom: 0,
  $right: 0,
  $position: absolute
) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}
@mixin animation($animation: default) {
  @include animation-support(animation, $animation);
}
// Apply an animation property and value with the correct browser support
@mixin animation-support($property, $value) {
  -webkit-#{$property}: $value;
  #{$property}: $value;
}
@mixin animation__delay--overwrite($ms) {
  -webkit-animation-delay: $ms !important;
  -moz-animation-delay: $ms !important;
  -o-animation-delay: $ms !important;
  animation-delay: $ms !important;
}

@mixin card-padding($defaultSize) {
  $paddings: (
    'small': $space-xl $space-xxl,
    'regular': 40px 56px
  );
  padding: find-value($defaultSize, $paddings);

  @include breakpoint(find-value('primary--min', $breakpoints)) {
    padding: find-value('small', $paddings);
  }
}

@mixin card() {
  height: 100%;
  background-color: $color__white;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:not(.inactive) {
    @include rounded-corners(small);
  }
  @include shadow();
  &.flat {
    box-shadow: none;
    border-radius: 0;
    &:hover {
      box-shadow: none;
    }
  }
  &.not-interactive {
    @include shadow-not-interactive();
  }
  &.green-card {
    background-color: $color__secondary;
    color: $color__white;
  }

  .card-content {
    @include card-padding('regular');
    flex: 1;
  }
}
@mixin checkmark($color, $thickness: '2px', $size) {
  width: $size;
  height: math.div($size * 5, 3);
  transform: rotate(45deg);
  border-width: $thickness;
  border-color: $color;
  border-bottom-style: solid;
  border-right-style: solid;
}

@mixin limit-screen-width() {
  max-width: 1240px;
  width: 100%;
  padding: 0 $space-md;
  margin: 0 auto;
}

@mixin full-screen-width() {
  max-width: none !important;
  padding: 0 !important;
}
