/*
  This file is a PFA version of Bootstraps "bootstrap-grid.scss" file, prepared for easy override of configuration variables.
 */

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

// *** VARIABLE OVERRIDES ************************************************************
//$grid-columns: 12;
$grid-gutter-width: 16px;

// changed: md 768px --> 800px
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 800px,
  lg: 992px,
  xl: 1200px
);
@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

//$container-max-widths: (
//  sm: 540px,
//  md: 720px,
//  lg: 960px,
//  xl: 1140px
//);
//@include _assert-ascending($container-max-widths, "$container-max-widths");
// ***********************************************************************************

@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/utilities/api';
@import 'bootstrap/scss/mixins/container';
@import 'bootstrap/scss/mixins/grid';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
