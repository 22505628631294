@import 'scss/abstracts/mixins';

co-quick-action-bar co-quick-action {
  vertical-align: top;
  box-shadow: none;
  $gutter: 15px;
  &:not(:last-of-type) {
    margin-right: $gutter;
  }
  @include breakpoint(find-value('primary', $breakpoints)) {
    margin-bottom: $gutter;
  }
}
