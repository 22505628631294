@import 'scss/abstracts/colors';

$pointer-width: 54px;

.ngx-slider-form {
  .small-input {
    &.mat-mdc-form-field {
      margin-bottom: 0px;
    }

    .mat-mdc-form-field-infix {
      border-top: 0px;
      width: 100%;
    }

    .mat-mdc-form-field-wrapper {
      margin-bottom: -1.34375em;
    }
  }

  .ngx-slider {
    margin: 15px 0 45px;

    .ngx-slider-pointer {
      width: $pointer-width;
      height: 32px;
      border-radius: 9px;
      background: $color__white;
      box-shadow: 0px 2px 2px 0px rgba($color__black, 0.2);
      top: -12px;

      &::before {
        background: transparent;
        border-left: 1px solid $color__black--20;
        border-right: 1px solid $color__black--20;
        height: 8px;
        width: 4px;
        top: 12px;
        left: 22.5px;
      }

      &::after {
        background: $color__black--20;
        height: 8px;
        width: 1px;
        top: 12px;
        left: 28.5px;
      }
    }

    .ngx-slider-bubble {
      font-size: 12px;
      color: $color__black--60;
      &.ngx-slider-limit {
        top: 25px;
        &::before {
          content: '';
          width: 1px;
          height: 40px;
          background: $color__black--10;
          position: absolute;
          top: -40px;
        }
      }

      &.ngx-slider-floor {
        margin-left: $pointer-width * 0.5;
        &::before {
          left: 0;
        }
      }

      &.ngx-slider-ceil {
        padding-right: $pointer-width * 0.5;
        &::before {
          margin-right: $pointer-width * 0.5;
          right: 0;
        }
      }
    }

    .ngx-slider-bar {
      height: 8px;
      border-radius: 0px;
      background: $color__black--10;
    }

    .ngx-slider-bar-wrapper {
      margin-left: $pointer-width * 0.5;
      &.ngx-slider-full-bar .ngx-slider-bar {
        max-width: calc(100% - #{$pointer-width});
      }
    }

    .ngx-slider-selection-bar .ngx-slider-bar {
      background: $color__secondary--80;
      max-width: calc(100% - #{$pointer-width});
    }
  }

  .ngx-slider[disabled] {
    .ngx-slider-pointer {
      background: $color__black--05;
      border: 0px;
      box-shadow: 0px 2px 2px 0px rgba($color__black, 0.2);

      &::before,
      &::after {
        top: 13.5px;
      }
    }

    .ngx-slider-bubble.ngx-slider-limit {
      top: 25px;
      &::before {
        color: $color__black--20;
      }
    }

    .ngx-slider-bar {
      background: $color__black--10;
    }

    .ngx-slider-selection-bar .ngx-slider-bar {
      background: $color__black--20;
      opacity: 0.4;
    }

    .ngx-slider-span {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}
