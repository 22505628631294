@import 'scss/abstracts/defaults';

mat-radio-group {
  display: flex;
  flex-direction: column;
  margin: $space-lg 0;

  &:first-child {
    margin-top: 0;
  }

  &.radio-row {
    flex-direction: row;
    mat-radio-button:not(:last-child) {
      margin-right: $space-lg;
    }
  }

  @media (max-width: 480px) {
    &.radio-row {
      flex-direction: column;

      mat-radio-button:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}

label mat-radio-group {
  margin: 0;
}
