@import 'scss/abstracts/defaults';
@import 'scss/abstracts/mixins';

.godkend-recap {
  .header-2 {
    @extend .h2;
    font-size: $font-size--bigger !important;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--bigger__desktop;
    }
  }
  .header-3 {
    @extend .h3;
    font-size: $font-size--big !important;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--big__desktop;
    }
  }
  .header-4 {
    font-family: LatoBold, sans-serif;
    margin-bottom: 0;
    font-size: $font-size--manchet !important;
    @include breakpoint(find-value('primary', $breakpoints)) {
      font-size: $font-size--manchet__desktop;
    }
  }
  table {
    width: 100%;
  }
}
