.bullet-list ul,
ul.bullet-list {
  list-style: disc;
  margin: 0;
  padding: 10px 0 20px 25px;
  li {
    margin-bottom: 5px;
    line-height: 130%;
  }
}

ul.bullet-list--compact {
  padding: 0 0 0 20px;
  li {
    margin-bottom: 0;
  }
}

.number-list {
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
